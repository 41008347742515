'use strict'
require("babel-polyfill");
// var colorify = require('colorify.js');
import 'objectFitPolyfill';
import 'url-polyfill'
import 'nodelist-foreach-polyfill'
// import 'custom-event-polyfill'

import Swiper from 'swiper/bundle'
import SmoothScroll from './_SmoothScroll.js'
import ScrollHelper from './_ScrollHelper.js'
import ResizeHelper from './_ResizeHelper.js'
import Device from './_Device.js'
import './_Utility.js'

import Rellax from 'Rellax'
import inView from 'in-view'
import ScrollTrigger from '@terwanerik/scrolltrigger'
import Parallax from 'parallax-js'
import { each } from 'jquery';

const TRANSITION_END = 'webkitTransitionEnd transitionend';
const ANIMATION_END = 'webkitAnimationEnd Animationend';
const ANIMATION_START = 'webkitAnimationStart Animationstart';

const BREAK_POINT = 1024

function remap(value, in_min, in_max, out_min, out_max ) {
  return (value - in_min) * ((out_max - out_min) / (in_max - in_min)) + out_min;
}
class Kv {
  constructor() {
    this.queue = 0
    this.delay = 3
    this.keyframeIndex = 1
    this.prevframeIndex = null
    this.MAX_FRAME = 3
    this.ANIMATION_START = 'webkitAnimationStart Animationstart'
    this.ANIMATION_END = 'webkitAnimationEnd Animationend'
    this.bind()
  }
  bind() {
    this.el = $('#kv')
    this.nav = $('.js-kv-nav')
    const $kvobject = $('.kv__nav')
    // $kvobject.on(ANIMATION_START, this.start.bind(this))
    $kvobject.on(ANIMATION_END, this.next.bind(this))

    this.nav.on('click', this.pagination.bind(this))
  }
  play() {
    this.update()
  }
  next() {
    this.prevframeIndex = this.keyframeIndex
    this.keyframeIndex = (this.keyframeIndex++ % this.MAX_FRAME) + 1
    this.update()
    // setTimeout(() => {
    // }, this.delay)
  }
  update() {
    // this.el.attr('data-keyframe', 1)
    // return
    this.el.attr('data-keyframe', this.keyframeIndex)
    this.el.attr('data-keyframe-prev', this.prevframeIndex)
    $('.kv__grad').attr('data-keyframe', this.keyframeIndex)
  }
  pagination(e) {
    const index = e.currentTarget.getAttribute('data-navindex')
    console.dir(JSON.stringify({
      index: index,
      keyframeIndex: this.keyframeIndex,
      prevframeIndex: this.prevframeIndex,
    }, null, 2))
    if( index && index !== this.keyframeIndex ) {
      this.prevframeIndex = this.keyframeIndex
      this.keyframeIndex = index
      this.update()
    }
  }
}

$(() => {
  const $window = $(window);
  const $body = $('body');
  $body.addClass('loaded');


  // ---------------------------------------------------------------
  // kv 
  // ---------------------------------------------------------------
  const kv = new Kv();

  const FRICTION = 0.014
  let parallaxScenes = []
  const parallaxSwitcher = new Device({
    breakpoint: BREAK_POINT
  })
  parallaxSwitcher.on('pc', (e) => {
    document.querySelectorAll('.kv-slider__item').forEach( scene => {
      const parallaxInstance = new Parallax(scene);
      parallaxInstance.friction(FRICTION, FRICTION);
      parallaxScenes.push(parallaxInstance)
    })

  })
  parallaxSwitcher.on('sp', e => {
    if(parallaxScenes.length) {
      parallaxScenes.forEach( scene => {
        scene.destroy()
      })
      parallaxScenes = []
    }
  })
  parallaxSwitcher.resize()


  var rellax = new Rellax('.rellax', {
    center: true
  });

  
  $('#loading').on(TRANSITION_END, e => {
    e.stopPropagation()
    if(e.originalEvent.propertyName === 'opacity') {
      kv.play()
    }
  })


  // ---------------------------------------------------------------
  // recipe accordion
  // ---------------------------------------------------------------
  $('.js-accordion-more').each( (index, el) => {
    const $el = $(el)
    const $body = $el.find('.js-accordion-more-body')
    const height = $body.outerHeight(true)
    const $additional_content = $el.find('[data-show-afteropening]')

    $body.css('height', height)
    $el.attr('data-accordion-height', height).addClass('calculated')


    $el.find('.js-accordion-more-more').on('click', e => {
      $el.addClass('open')
      $el.on(TRANSITION_END, e => {
        $el.addClass('opened')
        $body.css('height', 'auto')
        $additional_content.show()
      })
    })
  })

  $('.js-accordion').on('click', e => {
    if(window.matchMedia(`(max-width:${BREAK_POINT}px)`).matches) {
      const $el =$(e.currentTarget)
      const targetID = $el.attr('data-target')
      const $target = $(targetID)
      $el.toggleClass('active')
      // console.log($target)
      $target.slideToggle();
    }
  })

  // ---------------------------------------------------------------
  // tab 
  // ---------------------------------------------------------------
  const $tab = $('.js-tab-control');
  $tab.find('.js-tab-item').on('click', e => {
    const $el = $(e.currentTarget)
    const $active = $tab.find('.active')
    $active.removeClass('active')
    $el.toggleClass('active')
    
    
    const currentContent = $active.attr('data-target')
    const newContent = $el.attr('data-target')
    // console.log(currentContent, newContent)

    $(currentContent).hide();
    $(newContent).fadeIn();

      // location.href = newContent
      scrollTo(calcScrollTo($(newContent)));

  })

  


  // let keyframeIndex = 1
  // const MAX_FRAME = 2
  // setInterval( () => {
  //   keyframeIndex = (keyframeIndex++ % MAX_FRAME) + 1
  //   $('#kv').attr('data-keyframe', keyframeIndex)
  //   $('.kv__grad').attr('data-keyframe', keyframeIndex)

  //   let queue = 0
  //   $('.kv__object').on(ANIMATION_START, e => {
  //     console.log('ANIMATION_START')
  //     queue++
  //   })
  //   $('.kv__object').on(ANIMATION_END, e => {
  //     console.log('ANIMATION_END')
  //     queue--
  //     console.log(queue)
  //   })
  //   // $('#kv').on(TRANSITION_END, e => {
  //   //   console.log('TRANSITION_END', e)
  //   // })
  // }, 4000)

  // ---------------------------------------------------------------
  // scroll effect 
  // ---------------------------------------------------------------
  let windowHeight = window.innerHeight;
  const resizeHelper = new ResizeHelper(() => {
    windowHeight = window.innerHeight;
  })
  const scrolled = new ScrollHelper(function(){
    $body.toggleClass('scrolled', $(window).scrollTop() >= windowHeight * .25);
  });

  const trigger = new ScrollTrigger({
    trigger: {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0
          }
        },
        viewport: {
          x: 0,
          y: (trigger, frame, direction) => {
            return 0.1
          }
        }
      },
    },
  })
  trigger.add('[data-trigger]')
  const trigger_inner = new ScrollTrigger({
    trigger: {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0
          }
        },
        viewport: {
          x: 0,
          y: (trigger, frame, direction) => {
            return 0.3
          }
        }
      },
    },
  })
  trigger_inner.add('[data-trigger-inner]')



  // ---------------------------------------------------------------
  // slider 
  // ---------------------------------------------------------------

  const galleryItems = document.querySelectorAll('.js-gallery-slider')
  const gallerySlider = []
  for (let el of galleryItems) {
    if (el.querySelectorAll('.swiper-slide').length === 1) {
      el.classList.add('swiper-disable')
      continue;
    }
    gallerySlider.push(
      new Swiper(el, {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        // loopedSlides: 18,
        // slidesOffsetBefore: (window.innerWidth - 294) * 0.5,

        // noSwiping: false,
        // threshold: 10,
        // allowTouchMove: true,
        // spaceBetween: 0,
        observer: true,
        observeParents: true,
        
        // effect: 'fade',


        speed: 600,
        loop: true,
        // loopFillGroupWithBlank: false,

        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        // },
        // autoplay: {
        //   delay: 5000,
        //   clickable: true,
        //   disableOnInteraction: false,
        // },
        // navigation: {
        //   nextEl: el.querySelector('.swiper-button-next'),
        //   prevEl: el.querySelector('.swiper-button-prev'),
        // },
        pagination: {
          clickable: true,
          el: el.querySelector('.swiper-pagination'),
        },

        // autoplay

        // autoplay: {
        //   delay: 2000,
        //   disableOnInteraction: false,
        // },

        // sp
        // breakpoints: {
        //   [BREAK_POINT]: {
        //     allowTouchMove: false
        //   },
        // }
      })
    )
  }


  // itemSlider
  let itemSlider = [];
  const itemSliderSwitcher = new Device({
    breakpoint: BREAK_POINT
  })
  itemSliderSwitcher.on('sp', (e) => {
    document.querySelectorAll('.js-item-sliders').forEach( (slider, index) => {

      
      const nav = new Swiper(slider.querySelector('.js-nav-slider'), {
        slidesPerView: 'auto',
        slidesPerGroup: 3,
        spaceBetween: 0,
        centeredSlides: false,
        loop: false,
  
        noSwiping: true,
        allowTouchMove: false,
  
        speed: 400,
      })
      
      const product = new Swiper(slider.querySelector('.js-item-slider'), {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        spaceBetween: 0,
        centeredSlides: true,
        noSwiping: true,
        allowTouchMove: false,
        loop: false,
  
        noSwiping: false,
        allowTouchMove: true,
        speed: 400,

        thumbs: {
          swiper: nav
        },

        pagination: {
          clickable: true,
          el: slider.querySelector('.swiper-pagination'),
        },
      })


      itemSlider.push({
        "nav": nav,
        "product": product
      })
    })

  })
  itemSliderSwitcher.on('pc', (e) => {
    if(itemSlider.length) {
      itemSlider.forEach( item => {
        item['nav'].destroy()
        item['product'].destroy()
      })
      itemSlider = []
    }
  })
  itemSliderSwitcher.resize()


  // craftsmanship gallery
  const responsiveSlideElements = document.querySelectorAll('.js-gallery-slider_responsive')
  const responsiveSliderSwitcher = new Device({
    breakpoint: BREAK_POINT
  });
  let responsiveSlider = []
  responsiveSliderSwitcher.on('sp', (e) => {
    for (let el of responsiveSlideElements) {
      if (el.querySelectorAll('.swiper-slide').length === 1) {
        el.classList.add('swiper-disable')
        continue;
      }
      responsiveSlider.push(
        new Swiper(el, {
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          allowTouchMove: true,
          spaceBetween: 0,
          speed: 600,
          loop: false,
          centeredSlides: true,
          observer: true,
          observeParents: true,

          // loopFillGroupWithBlank: false,
          // navigation: {
          //   nextEl: el.querySelector('.swiper-button-next'),
          //   prevEl: el.querySelector('.swiper-button-prev'),
          // },
          // pagination: {
          //   el: el.querySelector('.swiper-pagination'),
          // },
          // sp
          noSwiping: false,
          threshold: 10,
          allowTouchMove: true,
          // breakpoints: {
          //   960: {
          //     // allowTouchMove: true
          //     noSwiping: false,
          //     threshold: 10,
          //     allowTouchMove: true,
          //   },
          // }
        })
      )
    }
  })
  
  responsiveSliderSwitcher.on('pc', (e) => {
    for (let el of responsiveSlider) {
      if(responsiveSlider.length) {
        el.destroy();
      }
    }
    responsiveSlider = []
  })
  responsiveSliderSwitcher.resize()

  // SP brand/dyeing
  // let responsiveSlider;
  // const responsiveSliderSwitcher = new Device({
  //   breakpoint: BREAK_POINT
  // })
  // responsiveSliderSwitcher.on('sp', (e) => {
  //   if( document.querySelector('.js-gallery-slider_responsive') ) {
  //     responsiveSlider = new Swiper('.js-gallery-slider_responsive', {
  //       slidesPerView: 'auto',
  //       slidesPerGroup: 1,
  //       spaceBetween: 0,
  //       centeredSlides: true,
  //       noSwiping: true,
  //       allowTouchMove: false,
  //       loop: true,
  //       // autoplay: {
  //       //   delay: 0,
  //       //   disableOnInteraction: false,
  //       // },
  
  //       // sp
  //       noSwiping: false,
  //       allowTouchMove: true,
  
  //       speed: 400,
  
  //       // pc 
  //       breakpoints: {
  //         960: {
  //           speed: 12000,
  //           noSwiping: true,
  //           allowTouchMove: false
  //         },
  //       }
  //     })

  //   }
  // })
  // responsiveSliderSwitcher.on('pc', (e) => {
  //   console.log('on pc')
  //   if(responsiveSlider) {
  //     console.log('destroy')
  //     responsiveSlider.destroy();
  //   }
  // })
  // responsiveSliderSwitcher.resize()

  // new Swiper('.mv-slider', {
  //   slidesPerView: 'auto',
  //   slidesPerGroup: 1,
  //   spaceBetween: 0,
  //   speed: 12000,
  //   centeredSlides: true,
  //   noSwiping: true,
  //   allowTouchMove: false,
  //   loop: true,
  //   autoplay: {
  //     delay: 0,
  //     disableOnInteraction: false,
  //   },
  // })

  // menu
  $('.js-toggle-menu').on('click', e => {
    $body.toggleClass('menu-active')
  })


  // smooth scroll ------------------------------------------------------------
  function scrollTo(top){
    $('html,body').animate({ scrollTop: top }, {'easing': 'swing', 'duration': 500});
  }
  function calcScrollTo($el){
    // const offset = $('#nav').outerHeight() || 100;
    const offset = 0;
    // console.log($('#nav').height())
    // console.log($('#nav').outerHeight())
    if($el.length){
      var to = $el.offset().top
    }else{
      var to = 0;
    }
    return to - offset;
  }
  $('a[href ^= "#"]').click(function(e){
    const $this = $(this);
    const id = $this.attr('href');
    if(id === '#') return false;
    const $el = $(id);
    scrollTo(calcScrollTo($el));

    if($this.hasClass('nohash')){
      e.preventDefault();
    }
    e.stopPropagation();
  });
});
