'use strict';

import 'url-polyfill';

const BREAK_POINT = 750;
const DESIGN_VIEWPORT = 750;
const INVIEW_OFFSET = 30;

const SCROLL_ELEMENT = (() => {
  if ('scrollingElement' in document) {
    return document.scrollingElement;
  } else if (navigator.userAgent.toLowerCase().match(/webkit|msie 5/)) {
    return document.body;
  } else {
    return document.documentElement;
  }
})();

function vw(px){
  const _vw = (px/DESIGN_VIEWPORT) * window.innerWidth;
  return _vw;
}
class SmoothScroll{
  constructor(setting_arg){
    const config = {
      setonload: true,
      breakpoint: BREAK_POINT,
      offset: {
        pc: 0,
        sp: 0
      }
    }
    this.config = (Object.assign(config, setting_arg));
    this.callback_list = [];

    this.bindEvents();
    return;

  }
  bindEvents(){
    const hashList = document.querySelectorAll('a[href *= "#"]');
    const innerLinks = this.filter_otherPage(hashList);

    innerLinks.forEach(anchor => {
      anchor.addEventListener('click', this.onClick.bind(this))
    })

    if(this.config.setonload && location.hash !== ''){
      this.onload();
    }
  }
  filter_otherPage(hashlist){
    return Array.from(hashlist).filter((el, i, ary) => {
    const href = el.getAttribute('href');

    const url = new URL(href, location);
    const url_from = location.origin + location.pathname;
    const url_to = url.origin + url.pathname;

    return url_from === url_to;
    });
  }
  onClick(e){
    const href = e.currentTarget.getAttribute('href');
    const hash = '#' + href.split('#')[1];
    const targetElement = document.querySelector(hash);
    const targetPosition = this.calcPosition(targetElement);

    this.startScroll(targetPosition);

    e.preventDefault();
    e.stopPropagation();
  }
  calcOffset(){
    const is_sp = window.innerWidth < this.config.breakpoint;
    return is_sp ? vw(this.config.offset.sp) : this.config.offset.pc;
  }

  calcPosition(targetElement){
    const $targetElement = $(targetElement);
    const translate = $targetElement.hasClass('inview') && !$targetElement.hasClass('is-inview') ? INVIEW_OFFSET : 0;
    const offset = this.calcOffset() + translate;
    var to = targetElement ? $targetElement.offset().top - offset : 0;
    return to;
  }

  startScroll(to, speed){
    this.begin();
    $(SCROLL_ELEMENT).animate(
      { scrollTop: to },
      {
        'easing': 'swing',
        'duration': speed === 'fast' ? 500 : 800,
        'complete': this.complete.bind(this)
      }
    );
  }
  scrollImmediate(to){
    this.begin();
    this.startScroll(to, 'fast')
  }
  onload(){
    const images = document.querySelectorAll('img');
    let loadedImages = 0;
    window.addEventListener('load', () => {
      setTimeout(() => {
        const target_to_scroll = document.querySelector(location.hash);
        if(target_to_scroll){
          const targetPosition = this.calcPosition(target_to_scroll);
            this.scrollImmediate(targetPosition);
        }
      }, 300)
    });
  }

  // calback placeholder
  begin(){
    return null;
  }
  complete(){
    return null;
  }

}

export default SmoothScroll;
